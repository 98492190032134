@import '../_utils/functions/conversions';
$class: 'mt-spinner';

.#{$class} {

  &--large {
    width: px-to-rem(96);
    height: px-to-rem(96);
  }

  &--medium {
    width: px-to-rem(48);
    height: px-to-rem(48);
  }

  &--small {
    width: px-to-rem(24);
    height: px-to-rem(24);
  }

  &--mini {
    width: px-to-rem(16);
    height: px-to-rem(16);
  }
}
