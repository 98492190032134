@import '../_utils/variables/media-queries';

$class: 'mt-illustration';

.#{$class} {
  height: auto;
  width: px-to-rem(311);

  @media #{$mq-sm} {
    width: px-to-rem(544);
  }

  @media #{$mq-md} {
    width: px-to-rem(311);
  }

  &--small {
    width: px-to-rem(100);
    height: px-to-rem(100);
  }

  &--icon {
    width: px-to-rem(56);
    height: px-to-rem(56);
  }
}

