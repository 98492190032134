@font-face {
  font-family: Akkurat;
  src: url("./fonts/Akkurat-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  src: url("./fonts/Akkurat-Bold.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-style: italic;
  src: url("./fonts/Akkurat-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: bold;
  font-style: italic;
  src: url("./fonts/Akkurat-Bold-Italic.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: 300;
  src: url("./fonts/Akkurat-Light-Regular.otf") format("opentype"); }

@font-face {
  font-family: Akkurat;
  font-weight: 300;
  font-style: italic;
  src: url("./fonts/Akkurat-Light-Italic.otf") format("opentype"); }

* {
  margin: 0;
  padding: 0;
  font-family: Akkurat;
  font-size: 14px; }

a {
  color: #0062DA;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

@media screen and (max-width: 640px) {
  .reveal {
    top: 9px;
    width: 95%;
    margin: auto;
    min-height: 0;
    padding-right: 33px;
    height: auto; } }

.field-label {
  color: #333333;
  line-height: 1.29; }

.field-value {
  color: #555555;
  font-weight: 300; }

.flex-column, .error422-message, .error-message-box {
  display: flex;
  flex-direction: column; }

.flex-row, .view-dashboard {
  display: flex;
  flex-direction: row; }

.small-icon {
  width: 22px;
  height: 22px; }

.parent-box {
  position: relative; }

.no-margin {
  margin: 0 !important; }

.account-info {
  display: none; }

.app-content {
  max-width: 982px; }

.covid-section {
  position: relative;
  justify-content: center;
  overflow: hidden;
  transition: height .2s ease-in;
  background: #e5effa;
  display: flex;
  height: 52px; }

.covid-parent {
  display: flex;
  text-align: center;
  padding: 8px 40px;
  font-size: 1em;
  font-weight: 300; }

.covid-text {
  align-self: center; }

.tell-me-more-link {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  padding: 8px;
  align-self: center;
  line-height: 1.25;
  font-weight: 500; }

.tell-me-more-link:link, .tell-me-more-link:visited {
  color: #0064d2; }

.close-covid-btn {
  position: absolute;
  height: 11px;
  width: 12px;
  right: 0;
  margin-right: 15px;
  top: 11px;
  cursor: pointer; }

.covid-gradient {
  border-image: linear-gradient(90deg, #0064d2, #5cd6e0 30%, #5e50bf);
  border-image-slice: 1;
  width: 100%;
  border-width: 2px 0 0;
  border-style: solid; }

.header-description {
  font-size: 12px;
  line-height: 1.33;
  color: #333333;
  padding: 28px 17px 14.1px 17px; }

@media (max-width: 466px) {
  .covid-section {
    height: 72px; }
  .close-covid-btn {
    margin-right: 11px; } }

.telstra-spinner-box {
  height: 50vh;
  display: flex; }

.telstra-spinner-box div {
  margin: auto;
  height: 48px;
  width: 300px; }

.telstra-spinner-box-mobile {
  height: 50vh;
  display: flex; }

.telstra-spinner-box-mobile div {
  margin: auto;
  height: 48px;
  width: 210px; }

.appointment-details-spinner-box {
  position: unset;
  top: 40%;
  left: 40%; }

.appointment-details-spinner-box-mobile {
  height: 50vh;
  display: flex; }

.spinner-box-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
  height: 24px; }

.appointment-details-spinner-box-desktop {
  height: 350px;
  width: 650px;
  display: flex; }

.appointment-details-spinner-box-desktop div {
  margin: auto;
  height: 55px;
  width: 55px; }

.spinner-message-desktop {
  font-family: 'Akkurat';
  font-size: 20px;
  padding-top: 30px; }

.error422-message {
  padding: 34px 16px 92px 16px;
  align-items: left; }

.error422-message .error422-message-content {
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  color: #333333;
  line-height: 25px;
  padding: 11px 32px 0 27px; }

.error422-message .error422-message-content .list_indent {
  margin-left: 30px; }

.error-message-box {
  margin: 15px 15px 0 15px;
  padding: 0 16px;
  align-items: left; }

.error-message-box img {
  height: 50px;
  width: 50px;
  padding-right: 15px; }

.error-message-box .error-message-content {
  font-size: 20px;
  font-weight: 300;
  color: #333333;
  line-height: 40px; }

.error-message-box .empty-header {
  padding: 21px; }

.error-message-box .empty-header img {
  float: left;
  padding-right: 20px; }

.error-message-box .empty-header .error-message-content {
  padding-top: 7px; }

.apppointment-slot-hidden {
  display: none; }

.apppointment-slot-shown {
  display: block; }

.slot-box {
  word-wrap: break-word;
  border-radius: 15px;
  border: 2px solid grey;
  padding: 20px;
  width: 100%;
  height: 50px;
  margin: 10px 0px;
  text-align: center;
  cursor: pointer; }

.show-more-button {
  background-color: black;
  color: white;
  width: 100%;
  border: 2px solid black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; }

.show-more-button:hover {
  background-color: #008CBA;
  color: white; }

.slot-msg {
  background-color: rgba(192, 192, 192, 0.3);
  border-style: solid;
  border-width: 1px;
  padding: 10px; }

.error-label {
  margin-bottom: 20px; }

.slot-box:hover {
  background-color: #008CBA;
  color: white; }

.appointment-confirm-border {
  border: solid 1px #1964c8;
  padding: 20px;
  margin: 20px; }

.appointment-change-text {
  border-style: blue;
  padding: 20px;
  margin: 20px; }

.appointment-manager-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%; }

.appointment-manager-heading {
  font-family: 'Akkurat';
  font-size: 16px; }

.appointment-manager-slots-slots-heading-desktop {
  font-family: 'Akkurat';
  font-size: 40px;
  line-height: 52px;
  font-weight: bold; }

.appointment-details-heading-desktop-work {
  font-size: 25px !important; }

.appointment-manager-divider {
  display: block;
  color: #E6E6E6;
  border-width: 1px;
  margin: 0; }

.appointment-manager-divider-slot {
  display: block;
  color: #E6E6E6;
  border-width: 1px;
  margin: 0;
  max-width: 98%; }

.appointment-manager-details-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 85%; }

.appointment-manager-details-heading {
  font-family: 'Akkurat';
  font-size: 18px;
  padding-top: 15%;
  margin-bottom: 15%;
  font-weight: bold; }

.appointment-details-header-block {
  margin-bottom: 5%; }

.appointment-details-font-light {
  font-family: 'Telstra Akkurat Light';
  color: #414141; }

.appointment-details-font-bold {
  font-size: 15px;
  color: #414141;
  font-weight: bold;
  margin-bottom: 5%; }

.appointment-manager-slot-msg-content {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  padding: 10px;
  margin-bottom: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }

.appointment-manager-slot-msg-content-row {
  display: flex; }

.appointment-manager-slot-msg-content-column {
  float: left; }

.eu-msg {
  font-family: 'Akkurat';
  font-size: 15px;
  justify-content: center;
  align-items: center;
  height: fit-content; }

.reschedule-text {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 23px;
  margin-top: 32px; }

.cancel-reschedule-text {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 23px; }

.reschedule-text-mobile {
  text-decoration: none;
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 23px;
  margin-top: 32px; }

.cancel-reschedule-text-mobile {
  text-decoration: none;
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 23px;
  padding-top: 16px; }

.appointment-details-reschedule-text {
  box-sizing: border-box;
  height: 56px;
  width: 90%;
  border: 2px solid #0064D2;
  background-color: #FFFFFF;
  align-items: center;
  margin-bottom: 3%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 2%;
  color: blue;
  text-align: center;
  display: inline-flex;
  margin-right: 5%; }

.rescheduleLinkIcon {
  padding-bottom: 5%; }

.rectangle {
  height: 3px;
  background: linear-gradient(90deg, #0064D2 0%, #5CD6E0 30.69%, #5E50BF 100%); }

.appointment-manager-header-confrim-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 10%;
  margin-top: 5%; }

.appointment-manager-heading {
  font-family: 'Akkurat';
  font-size: 16px;
  color: #414141; }

.appointment-manager-success-icon-div {
  display: flex;
  height: 100px;
  width: 100%;
  margin-top: 40px; }

.appointment-manager-sucess-icon {
  height: 100px;
  width: 100px;
  margin-right: 35%;
  margin-left: 40px; }

.appointment-manager-success-message-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px; }

.appointment-manager-sucess-message {
  height: 56px;
  width: 70%;
  color: #282828;
  font-family: "Akkurat";
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 28px;
  text-align: left;
  margin-right: 40px;
  margin-top: 24px; }

.appointment-manager-success-email-message-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  margin-bottom: 40px; }

.appointment-manager-success-email-message {
  height: 48px;
  width: 80%;
  color: #414141;
  font-family: "Akkurat";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.1px;
  line-height: 24px;
  text-align: left; }

.appointment-close-div {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  border: 2px solid #0064D2;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center; }

.appointment-close-button-label {
  height: 19px;
  width: 78.14px;
  color: #0064D2;
  font-family: "Akkurat";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 19px;
  text-align: center; }

.appointment-manager-confirm-date-div {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px; }

.appointment-manager-confirm-date {
  height: 56px;
  width: 327px;
  color: #000000;
  font-family: "Akkurat";
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 28px;
  text-align: center; }

.available-Appointment-Calendar-container {
  padding-top: 40px;
  padding-bottom: 16px; }

.change-btn-div-slots {
  padding-top: 8px; }

.arrow-change-date {
  color: #0064D2;
  position: relative;
  bottom: 2px; }

.slot-Container {
  width: 600px;
  margin-bottom: 10%;
  background-color: rgba(255, 255, 255, 0.3); }

#showMore {
  padding: 5% 0 4% 0; }

.show-more-button-desktop {
  font-size: 24px;
  line-height: 40px; }

.reschedule-Link-Icon-Desktop {
  padding-bottom: 10px; }

.font-time-subtitle {
  font-weight: 200; }

.appt-date-container {
  margin-bottom: 8px; }

.confirm-appt-text-slots {
  font-family: "Akkurat";
  font-size: 32px;
  line-height: 41.6px;
  font-weight: lighter; }

.appt-slot-text-container {
  margin-top: 16px; }

.calendar-widget {
  position: absolute;
  width: 46%;
  height: auto; }

.react-calendar {
  width: 100% !important;
  height: 90%;
  line-height: 5em !important;
  border: none !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  padding-bottom: 3%; }
  .react-calendar .react-calendar__navigation {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2);
    margin: 0 5% 5% 5% !important; }

.react-calendar__navigation button[disabled] {
  background-color: #ffffff !important; }

.calendar-widget button abbr {
  color: #0064D2;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  font-family: "Akkurat"; }

.react-calendar__tile:disabled {
  background-color: #ffffff !important; }
  .react-calendar__tile:disabled abbr {
    color: #AAAAAA !important; }

.react-calendar__tile--active {
  background: #ededed !important; }

.react-calendar__navigation button {
  font-size: 18px;
  font-family: Akkurat; }

.react-calendar__navigation__arrow {
  font-size: 25px;
  color: #787878; }

.calendar-widget abbr[title] {
  border: none;
  text-decoration: none;
  font-family: akkurat;
  font-size: 15px;
  font-weight: 500; }

.appointment-manager-slot-list div {
  cursor: pointer; }

.appointment-manager-slot-list-content-row {
  display: flex;
  cursor: pointer;
  padding: 24px 0 24px 0; }

.appointment-show-more a, .change-btn-div-slots a {
  text-decoration: none; }

.appointment-manager-slot-list-content-column2 {
  color: #cacaca; }

.change-link {
  font-size: 20px;
  line-height: 32px; }

.appointment-show-more {
  margin-top: 33px; }

.appointment-manager-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%; }

.appointment-manager-divider {
  display: block;
  color: #E6E6E6;
  border-width: 1px;
  margin: 0; }

.appointment-manager-heading {
  font-family: 'Akkurat';
  font-size: 16px; }

.appointment-manager-error-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  margin-top: 40px; }

.appointment-error-msg {
  text-align: center;
  padding: 24px 20px 24px 20px;
  font-size: 24px;
  font-weight: bold; }

.view-appt-appointment-error-msg {
  text-align: left;
  padding: 20px;
  font-size: 22px;
  font-weight: bold; }

.appointment-error-sub-text {
  text-align: center;
  font-size: 16px;
  opacity: 0.5;
  padding: 0 15px 0 15px; }

.view-appointment-error-sub-text {
  text-align: left;
  font-size: 19px;
  opacity: 0.5;
  padding: 0 20px 20px 20px; }

.appointment-error-btn {
  box-sizing: border-box;
  height: 56px;
  border: 1px solid #0064D2;
  margin: 8% 3% 0 3%; }

.done-btn-text {
  color: #0064D2;
  font-size: 18px;
  font-weight: bold; }

.done-btn {
  margin-top: 4%;
  text-align: center; }

.appointment-error-main-content-desktop {
  padding-left: 106px;
  padding-right: 106px; }

.appointment-error-main-photo-div-desktop {
  padding-top: 80px;
  padding-bottom: 24px;
  text-align: center; }

.appointment-error-image {
  height: 241px;
  width: 241px; }

.appointment-error-main-msg-div-desktop {
  padding-bottom: 24px;
  text-align: center; }

.appointment-error-main-msg-heading-desktop {
  font-family: 'Akkurat';
  font-size: 40px;
  line-height: 52px;
  font-weight: 600;
  color: #282828; }

.appointment-error-secondary-msg-div-desktop {
  text-align: center; }

.appointment-error-secondary-msg-heading-desktop {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 36px;
  font-weight: lighter;
  color: #282828; }

.appointment-error-secondary-msg-heading-desktop-hyperlink {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 36px;
  font-weight: lighter;
  color: #0064D2;
  cursor: pointer; }

.appointment-error-secondary-msg-heading-mobile-hyperlink {
  font-family: 'Akkurat';
  font-size: 16px;
  font-weight: lighter;
  color: #0064D2;
  cursor: pointer; }

.appointment-error-button-div-desktop {
  padding-top: 24px;
  padding-bottom: 40px;
  text-align: center; }

.appointment-error-button-desktop {
  height: 56px;
  width: 312px;
  text-align: center;
  background-color: #0064D2; }

.appointment-error-button-div-desktop {
  padding-bottom: 40px; }

.appointment-error-close-div-desktop {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  border: 2px solid #0064D2;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 35%;
  width: 26%;
  cursor: pointer;
  margin-top: 24px; }

.appointment-error-close-div-mobile {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 35%;
  width: 26%;
  cursor: pointer;
  margin-top: 40px; }

.appointment-error-message-us-div-mobile {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 25%;
  width: 46%;
  cursor: pointer;
  margin-top: 40px; }

.view-available-appointment-error-close-div-desktop {
  box-sizing: border-box;
  height: 56px;
  border: 2px solid #0064D2;
  background-color: #FFFFFF;
  display: inline-block;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.view-available-appt-text-div {
  display: flex;
  padding: 18px 18px 0 18px; }

.view-available-appointment-button-div {
  text-align: center;
  padding-bottom: 80px; }

.view-appointments-error-button {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  cursor: pointer; }

.show-available-appointments-error-button {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px; }

.appointment-error-close-button-label-mobile {
  height: 54px;
  color: #0064D2;
  font-family: "Akkurat";
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 19px;
  text-align: center;
  font-weight: bold; }

.appointment-error-close-button-label-desktop {
  font-weight: bold;
  color: #0064D2;
  font-family: "Akkurat";
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 19px;
  text-align: center;
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  margin-bottom: 5%;
  margin-left: 35%;
  width: 26%;
  cursor: pointer;
  margin-top: 24px; }

.appointment-error-button-mobile {
  padding-left: 100px;
  padding-right: 100px; }

.view-available-appt-error-mobile-button {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 48px;
  width: 100%;
  padding-left: 20px; }

.view-available-appointment-button-label-desktop {
  font-weight: bold;
  color: #0064D2;
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 19px;
  text-align: center;
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  margin-bottom: 5%;
  margin-left: 36%;
  width: 26%;
  cursor: pointer;
  margin-top: 24px; }

.appointment-manager-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%; }

.appointment-manager-divider {
  display: block;
  color: #E6E6E6;
  border-width: 1px;
  max-width: 100%;
  margin: 0; }

.appointment-manager-content-slots {
  padding: 0 0 24px 27px; }

.appointment-manager-heading {
  font-family: 'Akkurat';
  font-size: 16px; }

.appointment-manager-slots-calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 85%; }

.appointment-manager-slots-slot-header-div {
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: fit-content; }

.appointment-manager-content-details {
  padding: 0 0 20px 20px; }

.appointment-manager-details-header-div {
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: fit-content; }

.appointment-manager-details-header-div-mobile {
  padding-bottom: 24px;
  padding-top: 40px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: fit-content; }

.appointment-current-appointment-heading {
  font-family: 'Akkurat';
  font-size: 40px;
  line-height: 52px;
  font-weight: bold;
  padding-top: 80px;
  padding-left: 20px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: fit-content; }

.appointment-current-appointment-heading-mobile {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 33.6px;
  font-weight: bold;
  padding-top: 40px;
  padding-left: 26px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: fit-content;
  color: #282828; }

.appointment-manager-details-type-div {
  padding-bottom: 40px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: fit-content; }

.appointment-manager-details-type-div-mobile {
  padding-bottom: 40px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: fit-content;
  padding-top: 16px; }

.progress-link-dual {
  display: flex;
  align-items: center; }

.appointment-manager-details-header {
  display: inline-block; }

.appointment-manager-details-heading {
  font-family: 'Akkurat';
  font-size: 18px;
  line-height: 25px;
  font-weight: bold; }

.appointment-manager-details-heading-light {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 30px; }

.appointment-manager-slots-slot-header {
  display: inline-block; }

.appointment-manager-slots-slots-heading {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 28px;
  font-weight: bold; }

.appointment-manager-slots-slots-heading-light {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 30px; }

.appointment-manager-slots-calendar-heading {
  font-family: 'Akkurat';
  font-size: 24px;
  padding-left: 10%;
  padding-top: 15%;
  padding-bottom: 15%;
  font-weight: bold; }

.appointment-manager-slots-heading-mobile {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 33.6px;
  font-weight: bold; }

.appointment-manager-slots-heading-light-mobile {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  padding-top: 8px; }

.appointment-manager-slots-divider {
  display: block;
  color: #E6E6E6;
  border-width: 1px;
  max-width: 100%;
  margin: 0px 20px 0px 0px; }

.appointment-manager-slot-msg-content {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  padding: 10px;
  margin-bottom: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }

.appointment-manager-slot-msg-details {
  padding-left: 5%;
  padding-right: 5%; }

.rectangle {
  height: 2px;
  background: linear-gradient(90deg, #bf50b9 0%, #5CD6E0 30.69%, #5E50BF 100%); }

.appointment-manager-info-box {
  padding: 10px;
  padding-left: 6px;
  max-width: 90%; }

.appointment-manager-slot-msg-content-row {
  display: flex; }

.appointment-manager-slot-msg-content-column {
  float: left; }

.appointment-manager-slot-info-box {
  max-width: 120%;
  padding-left: 10px;
  padding-right: 18px; }

.appointment-manager-slot-message-content-row {
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 0px; }

.appointment-manager-slot-message-content-column {
  float: left;
  font-weight: 100; }

.eu-msg-slots-mobile {
  font-family: 'Akkurat';
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  height: fit-content;
  color: #414141; }

.calendarOpenText {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 30px;
  font-weight: lighter; }

.appointment-slots-click-here-link-mobile {
  padding-top: 16px; }

.calendarOpenLink {
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px; }

.appointment-manager-slots-change-date-mobile {
  padding-top: 40px;
  padding-bottom: 24px; }

.list-root {
  width: '100%'; }

.list-inline {
  display: 'inline'; }

.reschedule-text-mobile {
  text-decoration: none;
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 23px;
  margin-top: 32px; }

.cancel-reschedule-text-mobile {
  text-decoration: none;
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 23px;
  padding-top: 16px; }

.show-more-mobile-button {
  text-decoration: none; }

.show-more-mobile-button:hover {
  text-decoration: none; }

.appointment-manager-slot-list-content-box {
  padding: 24px 0px 24px 0px; }

.appointment-manager-slot-list {
  padding-right: 2%; }

.appointment-manager-slot-list-content-row-mobile {
  display: flex;
  cursor: pointer;
  padding: 16px 16px 16px 0; }

.appointment-manager-slot-list-text-date {
  line-height: 22.4px;
  font-weight: bold;
  font-size: 16px; }

.appointment-slots-date-time-padding-mobile {
  padding-top: 8px; }

.appointment-manager-slot-list-text-time {
  line-height: 20px;
  font-size: 14px; }

.appointment-manager-slot-list-content-column1 {
  float: left; }

.appointment-manager-slot-list-content-column2 {
  margin-left: auto;
  margin-right: 0;
  font-size: 30px;
  font-weight: 100; }

.show-more-button-mobile {
  padding-bottom: 40px;
  padding-top: 32px;
  font-size: 16px;
  line-height: 24px; }

.showMoreIcon {
  padding-bottom: 2%; }

.appointment-manager-slot-msg-content-details-mobile {
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  border-width: 1px;
  padding: 0px 10px;
  margin-bottom: 0;
  box-shadow: 0 2px 5px rgba(0, 2, 0, 0.3); }

.appointment-manager-slot-message-content {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  padding: 10px;
  margin-bottom: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }

.appointment-manager-slot-msg-content-slots-mobile {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  box-shadow: 0 2px 5px rgba(0, 2, 0, 0.3); }

.appointment-manager-slot-msg-content-slots {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  margin-top: 32px;
  box-shadow: 0 2px 5px rgba(0, 2, 0, 0.3);
  width: 464px; }

.appointment-manager-details-header-div-telstra {
  padding-top: 40px;
  display: flex;
  justify-content: left;
  height: fit-content;
  width: 686px; }

.appointment-manager-slots-slots-heading-dual {
  font-family: 'Akkurat';
  font-size: 40px;
  line-height: 52px;
  font-weight: bold;
  padding-top: 40px; }

.appointment-manager-slots-slots-heading-dual-mobile {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 33.6px;
  font-weight: bold; }

.appointment-manager-slots-slots-heading-dual-wrapper-mobile {
  padding-right: 27px; }

.calendar-main-div-mobile {
  position: fixed;
  background: white;
  z-index: 1012;
  height: 100%;
  top: 15%;
  width: 100%;
  left: 0;
  right: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px; }

.calendar-mobile-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-bottom: 5%; }

.appointment-manager-calendar-date {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content; }

.calendarOpenLink-calendar-date-text1 {
  font-family: 'Akkurat';
  font-size: 18px; }

.calendarOpenLink-calendar-date-text2 {
  font-family: 'Akkurat';
  font-size: 14px; }

.appointment-manager-calendar-date-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%; }

.appointment-manager-calendar-date-button {
  box-sizing: border-box;
  height: 45px;
  width: 80%;
  border: 2px solid #0064D2;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center; }

.appointment-manager-calendar-date-button-text {
  height: 19px;
  width: 78.14px;
  color: #0064D2;
  font-family: "Telstra Akkurat";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 19px;
  text-align: center; }

.appointment-manager-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%; }

.appointment-manager-divider {
  display: block;
  color: #E6E6E6;
  border-width: 1px;
  max-width: 100%;
  margin: 0; }

.appointment-manager-heading {
  font-family: 'Akkurat';
  font-size: 16px; }

.appointment-manager-confirm-screen-header {
  display: flex;
  justify-content: left;
  align-items: left;
  height: fit-content; }

.appointment-manager-second-confirm-heading {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 33.6px;
  padding-top: 40px;
  font-weight: bold; }

.appointment-manager-second-confirm-headings {
  padding-top: 24px;
  padding-bottom: 16px;
  font-family: 'Akkurat';
  font-size: 20px;
  font-weight: lighter; }

.appointment-manager-slot-msg-content {
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  border-width: 1px;
  padding: 10px;
  margin-bottom: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }

.appointment-manager-slot-msg-content-confirm-mobile {
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  border-width: 1px;
  padding: 2px 10px;
  margin-bottom: 0;
  box-shadow: 0 2px 5px rgba(0, 2, 0, 0.3); }

.appointment-manager-slot-msg-age {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 100; }

.appointment-manager-slot-msg-contentsconfirm {
  font-family: 'Akkurat';
  font-weight: bold;
  text-align: center;
  color: blue; }

.appointment-manager-slot-msg {
  padding-bottom: 15%; }

.appointment-manager-slot-confirm-msgs {
  padding-right: 26px;
  padding-bottom: 16px;
  padding-top: 40px; }

.rectangle {
  height: 2px;
  background: linear-gradient(90deg, #0064D2 0%, #5CD6E0 30.69%, #5E50BF 100%); }

.appointment-manager-info-boxes {
  padding: 10px;
  max-width: 105%;
  padding-left: 1px; }

.appointment-manager-slot-msg-content-row {
  display: flex;
  font-size: 18px; }

.appointment-manager-slot-msg-content-column {
  float: left;
  font-size: 18px; }

.appointmentTextLine {
  font-family: 'Akkurat';
  font-weight: bold;
  font-size: 20px;
  line-height: 28px; }

.appointmentTextLines {
  font-family: 'Akkurat';
  font-weight: bold !important;
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 200; }

.appointment-confirm-date-time-padding {
  padding-top: 8px; }

.appointment-manager-change-date {
  padding-top: 40px; }

.appointment-confirm-dual-appt-div {
  padding-top: 0%;
  color: #282828; }

.appointment-manager-change-dates {
  padding-bottom: 32px;
  font-size: 16px; }

.cancel-reschedule {
  font-size: 16px;
  font-weight: normal; }

.list-root {
  width: '100%'; }

.list-inline {
  display: 'inline'; }

.appointment-close-confirm-div {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  border: 1px solid #0064D2;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 3px; }

.appointment-close-confirm-button-label {
  color: #0062DA;
  font-family: "Akkurat";
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: center; }

.dont-change-button {
  font-size: 16px;
  font-weight: 500; }

.change-date-confirm-appt {
  color: #0064D2;
  font-family: "Akkurat";
  font-size: 20px;
  line-height: 24px; }

.dont-change-confirm-appt {
  color: #0064D2;
  font-family: "Akkurat";
  font-size: 16px;
  line-height: 24px; }

.line-details-confirm {
  border-top: 1px solid black;
  margin-left: -20px;
  margin-top: 10%; }

.appointment-manager-content-dual-mobile {
  padding: 40px 0 32px 26px; }

.appointment-manager-confirm-header-dual-mobile {
  padding: 8px 26px 0 26px; }

.appointment-manager-second-confirm-headings-dual {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  padding-top: 32px; }

.appointment-manager-slot-confirm-msgs-dual {
  padding-right: 5%;
  padding-bottom: 2%; }

.appointment-manager-date-time-slot {
  padding-top: 40px;
  padding-bottom: 16px; }

.confirm-appt-mobile {
  padding-left: 20px; }

.confirm-appt-desktop {
  padding-left: 20px; }

.confirm-heading-div {
  font-family: "Akkurat";
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 40px; }

.confirm-heading-div-dual {
  font-family: "Akkurat";
  font-weight: bold;
  margin-top: 80px; }

.confirm-appt-title-confirm-appt-text {
  font-family: 'Akkurat';
  font-size: 32px;
  line-height: 41.6px;
  font-weight: bold; }

.confirm-heading-text {
  font-size: 40px;
  line-height: 52px;
  font-family: "Akkurat";
  font-weight: bold; }

.confirm-appt-text {
  font-family: 'Akkurat';
  font-size: 32px;
  line-height: 41.6px;
  font-weight: bold; }

.confirm-appt-title {
  font-weight: bold; }

.confirm-appt-subtitle {
  font-weight: 300; }

.confirm-appt-subtitle-div {
  padding-top: 8px;
  padding-bottom: 40px; }

.confirm-appointment-slot-div {
  font-family: "Akkurat";
  font-size: 32px;
  font-weight: lighter;
  line-height: 44.8px;
  color: #414141; }

.change-link-confirm {
  color: #0064D2;
  font-family: "Akkurat";
  font-size: 20px;
  line-height: 32px;
  margin-top: 16px; }

.appt-confirm-non-eu-msg {
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  border-width: 1px;
  padding: 10px;
  margin-bottom: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); }

.appt-confirm-content-column {
  float: left;
  font-size: 18px; }

.appt-confirm-msg-content {
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  border-width: 1px;
  padding: 10px;
  margin-top: 2%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3); }

.appt-confirm-msg-content-row {
  display: flex;
  font-size: 18px; }

.appt-confirm-msg-content-column {
  float: left; }

.appt-confirm-age-text {
  margin-top: 2%; }

.appt-confirm-info-box {
  padding: 10px; }

.rectangle {
  height: 3px;
  background: linear-gradient(90deg, #0064D2 0%, #5CD6E0 30.69%, #5E50BF 100%); }

.appt-confirm-msg-age {
  margin-bottom: 0;
  height: 2.37%;
  width: 32.72%;
  color: #414141;
  font-family: "Akkurat";
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.15px;
  line-height: 30px; }

.appt-confirm-content {
  margin-top: 80px;
  margin-bottom: 24px; }

.appointment-manager-slots-slots-sub-heading-desktop {
  font-family: 'Akkurat';
  font-size: 32px;
  line-height: 41.6px;
  font-weight: bold; }

.appt-confirm-border {
  border: 1px solid #0064D2;
  padding: 18px;
  margin-top: 40px;
  width: 280px;
  text-align: center;
  cursor: pointer;
  border-radius: 3px; }

.confirm-appt-btn {
  border: 1px solid #0064D2;
  padding: 16px;
  width: 300px;
  text-align: center;
  cursor: pointer;
  color: #0062DA;
  font-family: "Akkurat";
  font-weight: bold;
  text-align: center;
  border-radius: 3px; }

.confirm-appt-btn span {
  font-size: 20px;
  line-height: 24px; }

.dont-change-appt-btn {
  color: #0064D2;
  font-family: "Akkurat";
  margin-top: 2%; }

.dont-change-appt-btn span {
  font-size: 20px;
  line-height: 28px; }

.dont-change-div {
  cursor: pointer;
  margin-bottom: 80px; }

.non-eu-msg-container-desktop {
  width: 50%; }

.non-eu-msg-container-desktop-slots {
  width: 50%; }

.appointment-manager-slot-list-text-time-desktop {
  line-height: 20px;
  font-size: 16px;
  font-weight: 300; }

.appointment-manager-slot-list-content {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  padding: 10px 0px 10px 0px; }

.appt-confirm-content-desktop-container {
  margin-top: 3%; }

.change-btn-div-confirm {
  padding-top: 16px; }

.appointment-manager-content-dual {
  padding-left: 20px;
  padding-top: 40px; }

.appointment-manager-content-dual-button {
  padding-left: 20px;
  padding-top: 40px; }

.appointment-manager-change-dates-dual-desktop {
  padding-bottom: 24px; }

.appointmentTextLinesDualDesktop {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  padding-top: 8px; }

.appt-confirm-border-dual {
  border: 1px solid #0064D2;
  padding: 18px;
  width: 300px;
  text-align: center;
  cursor: pointer;
  border-radius: 3px; }

.appointment-details-main-content-desktop {
  padding-left: 20px; }

.appointment-details-heading-desktop {
  font-family: 'Akkurat';
  font-size: 30px;
  line-height: 52px;
  font-weight: 600;
  color: #282828; }

.appointment-confirm-appt-category {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 28px;
  font-weight: bold; }

.appointment-details-main-header-div-desktop {
  padding-top: 40px;
  padding-bottom: 40px; }

.appointment-reschedule-margin {
  padding-left: 48px;
  color: #282828; }

.appointment-manager-details-subheading-desktop {
  font-family: 'Akkurat';
  font-size: 32px;
  line-height: 41.6px;
  font-weight: bold; }

.appointment-manager-details-subheading-desktop-light {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 28px;
  padding-top: 8px;
  font-weight: bold; }

.appointment-details-date-desktop {
  font-family: 'Akkurat';
  font-size: 32px;
  color: #414141;
  line-height: 44.8px;
  font-weight: lighter; }

.appointment-details-banner-message-div-desktop {
  padding-top: 40px;
  padding-bottom: 40px; }

.appointment-details-banner-message-div-desktop-dual {
  padding-top: 40px; }

.appointment-details-banner-message-content {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  height: 58px;
  width: 464px; }

.appointment-details-banner-message-info-rectangle {
  height: 2px;
  width: 464px;
  background: linear-gradient(90deg, #0064D2 0%, #5CD6E0 30.69%, #5E50BF 100%); }

.appointment-details-banner-message-content-row {
  display: flex;
  height: inherit; }

.appointment-details-banner-message-content-column {
  float: left;
  align-self: center; }

.appointment-details-banner-message-content-text {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Akkurat';
  font-weight: lighter;
  color: #414141; }

.appointment-details-banner-message-info-box {
  height: 24px;
  width: 24px;
  margin-right: 8px;
  margin-left: 11px; }

.appointment-details-reschedule-button-div {
  padding-left: 20px;
  padding-bottom: 80px; }

#appointment-details-reschedule-button-text {
  font-size: 20px;
  line-height: 24px; }

.appointment-details-reschedule-button-text {
  border: 1px solid #0064D2;
  padding: 16px;
  width: 300px;
  text-align: center;
  cursor: pointer;
  color: #0062DA;
  font-family: "Akkurat";
  font-weight: bold;
  text-align: center;
  border-radius: 3px; }

.iframe-parent {
  background-color: white; }

.iframe-popup {
  position: absolute;
  background-color: white;
  height: 75%;
  top: 10%;
  width: 75%;
  left: 12%;
  z-index: 1012; }

.close-appt-manager-btn {
  position: absolute;
  height: 20px;
  width: 30px;
  right: 0;
  margin-right: 15px;
  top: 11px;
  cursor: pointer;
  max-width: 300%; }

.close-btn {
  position: absolute;
  z-index: 1012;
  width: 1.6%;
  top: 12%;
  right: 15%;
  cursor: pointer; }

.close-btn-span {
  margin-left: 30%;
  color: #ffffff; }

.reschedule-inline-error {
  margin-top: 20px; }

.rescheduleLinkIcons {
  padding-bottom: 1%; }

.reschedule-ponr-text-desktop {
  font-size: 20px; }

.reschedule-ponr-div-desktop {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-bottom: 80px;
  margin-top: 40px; }

.appointment-details-reschedule-button-div span {
  text-decoration: none; }

.link-arrow-desktop {
  margin-bottom: 1%; }

.appointment-resc-banner-message-content {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  margin-bottom: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  height: 92px;
  width: 352px;
  margin-top: 32px; }

.appt-date-msg-nbn-telstra {
  padding-left: 10%;
  margin-top: -30px; }

.appointment-resc-banner-message-info-rectangle {
  height: 3px;
  width: 352px;
  background: linear-gradient(90deg, #0064D2 0%, #5CD6E0 30.69%, #5E50BF 100%); }

.appointment-resc-reschedule-button-div {
  padding-top: 40px; }

.reschedule-dual-text {
  text-decoration: none !important;
  margin-top: 32px; }

.cancel-reschedule-btn {
  padding-top: 16px; }

.able-text-style--TextBigBodyShort {
  padding-top: 15px !important; }

.appointment-manager-header-details-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%; }

.appointment-manager-heading-details-mobile {
  font-family: 'Akkurat';
  font-size: 16px;
  color: #414141; }

.appointment-details-header-block-details-mobile {
  margin-bottom: 5%;
  margin-top: 8%; }

.appointment-details-font-light-details-mobile {
  font-family: 'Akkurat';
  color: #414141;
  font-size: 20px;
  font-weight: 200; }

.appointment-manager-details-subheading-mobile {
  font-family: 'Akkurat';
  font-size: 20px;
  line-height: 28px;
  font-weight: bold; }

.appointment-manager-details-subheading-light-mobile {
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 22.4px;
  padding-top: 8px;
  font-weight: bold; }

.appointment-details-font-bold-details-mobile {
  font-size: 20px;
  line-height: 30px;
  color: #414141;
  font-weight: lighter;
  padding-bottom: 32px; }

.appointment-details-font-bold-details-mobile-dual {
  font-size: 20px;
  line-height: 30px;
  color: #414141;
  font-weight: lighter;
  padding-bottom: 16px; }

.appointment-manager-detail-content-dual-mobile {
  padding: 16px 26px 26px 26px; }

.appointment-manager-slot-msg-content-column-details-mobile {
  float: left;
  font-size: 18px;
  font-weight: 100; }

.appointment-manager-slot-msg-content-details-mobile {
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 18px;
  border-width: 1px;
  padding: 0px 10px;
  margin-bottom: 0;
  box-shadow: 0 2px 5px rgba(0, 2, 0, 0.3); }

.reschedule-ponr-text {
  font-family: 'Akkurat';
  font-size: 15px;
  line-height: 23px; }

.reschedule-ponr-div {
  margin-top: 20px;
  padding-left: 2%; }

.reschedule-ponr-mobile-div {
  margin-top: 20px;
  padding-left: 5%; }

.line-details {
  border-top: 1px solid black;
  margin-left: -20px;
  margin-top: 10px; }

.reschedule-appt-link {
  margin-left: 6%; }

.link-arrow {
  margin-bottom: 3%; }

.appointment-reschedule-margin-mobile {
  color: #282828; }

.appointment-manager-success-icon-div-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 80px; }

.appointment-manager-sucess-icon-desktop {
  height: 80%;
  width: 80%; }

.appointment-manager-success-message-div-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  width: 100%; }

.appointment-manager-sucess-message-desktop {
  width: 100%;
  color: #282828;
  font-family: "Akkurat";
  font-size: 40px;
  font-weight: bold;
  line-height: 52px;
  text-align: center; }

.appointment-manager-confirm-date-div-desktop {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px; }

.appointment-manager-success-email-message-div-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 18px;
  padding-bottom: 24px; }

.appointment-manager-success-email-message-desktop {
  width: 500px;
  color: #414141;
  font-family: "Akkurat";
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  text-align: center; }

.appointment-manager-confirm-date-desktop {
  color: #000000;
  font-family: "Akkurat";
  font-size: 35px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 40px;
  text-align: center; }

.appointment-close-div-desktop {
  box-sizing: border-box;
  height: 56px;
  width: 100%;
  border: 1px solid #0064D2;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 37.5%;
  width: 25%;
  border-radius: 3px; }

.appointment-completed-div-desktop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 37.5%;
  width: 25%; }

.appointment-close-button {
  padding-bottom: 20px; }

.appointment-close-button-label-desktop {
  height: 19px;
  width: 78.14px;
  color: #0062DA;
  font-family: "Akkurat";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }

.appointment-close-div-mobile {
  box-sizing: border-box;
  height: 56px;
  width: 78%;
  margin-left: 11%;
  margin-right: 12%;
  border: 2px solid #0064D2;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center; }

.appointment-manager-slot-list-text-date-desktop {
  line-height: 28px;
  font-weight: bold;
  font-size: 20px; }

.appointment-manager-content-no-appts {
  padding: 0 0 20px 27px; }

.appointment-manager-no-appts-header-div-mobile {
  width: fit-content;
  padding-top: 40px;
  padding-bottom: 16px;
  display: flex;
  justify-content: left;
  height: fit-content; }

.appointment-manager-no-appts-header-div {
  width: fit-content;
  padding-top: 80px;
  display: flex;
  justify-content: left;
  height: fit-content;
  padding-bottom: 32px; }

.appointment-manager-no-appts-header {
  display: inline-block; }

.appointment-manager-no-appts-heading {
  font-family: 'Akkurat';
  font-size: 21px;
  line-height: 30px;
  font-weight: bold;
  padding-right: 27px; }

.appointment-manager-no-appts-text {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 33.6px;
  font-weight: bold; }

.appointment-manager-no-appts-text-desktop {
  font-family: 'Akkurat';
  font-size: 40px;
  line-height: 52px;
  font-weight: bold; }

.reschedule-dual-text {
  text-decoration: none !important; }

.reschedule-text {
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 23px;
  margin-top: 32px; }

.cancel-reschedule-text {
  margin-top: 16px;
  font-family: 'Akkurat';
  font-size: 24px;
  line-height: 23px; }

.reschedule-text-mobile {
  text-decoration: none;
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 23px;
  margin-top: 32px; }

.cancel-reschedule-text-mobile {
  text-decoration: none;
  font-family: 'Akkurat';
  font-size: 16px;
  line-height: 23px;
  padding-top: 16px; }

.link-arrow-desktop {
  margin-bottom: 2px; }

.telstra-logo {
  height: 32px;
  width: 28px;
  margin: 7px 12px 0 16px; }

.telstra-logo-appointment {
  height: 32px;
  width: 28px;
  margin: 7px 17px 0 26px; }

.header-label {
  font-size: 22px;
  text-align: left;
  color: #333333;
  padding-top: 5px; }

.header-box {
  padding: 14px 0 18px 0;
  margin: 0 !important; }

.auth-icon {
  height: 30px;
  width: 30px; }

.auth-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 43px;
  position: absolute;
  right: 17px;
  cursor: pointer; }
  @media screen and (min-width: 1024px) and (max-width: 1473px) {
    .auth-box {
      right: 45px; } }

.auth-label {
  font-size: 12px;
  color: #333333; }

.view-all-orders {
  padding-top: 1.5px;
  font-weight: bold;
  color: #00aaf3; }

.view-orders-icon {
  margin: 0 8px 0 15px; }

.view-dashboard {
  position: absolute;
  bottom: 0;
  cursor: pointer; }

.dashboard-box {
  background-image: url(./images/telstra-gradient-small.svg);
  background-size: cover;
  height: 0;
  position: relative;
  padding-top: 23%; }
  @media screen and (min-width: 640px) {
    .dashboard-box {
      background-image: url(./images/telstra-gradient-large.svg);
      padding-top: 8%; } }

@media screen and (min-width: 1024px) {
  .progress-step-align {
    width: 3.33%; }
  .order-progress-box {
    margin: 28px 28px 28px 0px; } }
